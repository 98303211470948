import { Container } from './styles'
import reactIcon from '../../assets/react-icon.svg'
import linkedin from '../../assets/linkedin.svg'
import githubIcon from '../../assets/github.svg'
import whatsapp from '../../assets/whatsapp.svg'
import telegram from '../../assets/telegram.svg'
import instagramIcon from '../../assets/instagram.svg'

export function Footer() {
  return (
    <Container className="footer">
      <a href="https://vhand.com.mx" className="logo">
        <span>vhand.</span>
        <span>com.mx</span>
      </a>
      <div>
        <p>
          <small>
Este sitio web fue creado con <img src={reactIcon} alt="React" />
          </small>
          {/* <span>❤️</span> */}
        </p>
      </div>
      <div className="social-media">
        <a
          href="https://api.whatsapp.com/send?phone=525632299204&text=Hola%2C%20quiero%20m%C3%A1s%20informaci%C3%B3n..."
          target="_blank"
          rel="noreferrer"
        >
          <img src={whatsapp} alt="Whatsapp" />
        </a>
      </div>
    </Container>
  )
}
