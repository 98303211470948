import { Container } from './styles'
import { BrowserRouter as Router } from 'react-router-dom'
import { NavHashLink, HashLink } from 'react-router-hash-link'
import { useState } from 'react'
import LogoBlanco from "../../assets/logo-blanco-full.png";
import LogoNegro from "../../assets/logo-negro-full.png";
import Resume from '../../assets/Vinayak_Singh_Resume.pdf'
export function Header() {
  const [isActive, setActive] = useState(false)
  const [logo, setLogo] = useState(LogoBlanco);
  function toggleTheme() {
    let html = document.getElementsByTagName('html')[0]
    html.classList.toggle('light')
    if (html.classList.contains('light')) {
      setLogo(LogoNegro);
    } else {
      setLogo(LogoBlanco);
    }
  }
  function closeMenu() {
    setActive(false)
  }
  return (
    <Container className="header-fixed">
      <Router>
        <HashLink smooth to="#home" className="logo">
          <img 
            src={logo} 
            alt="Vhand Logo" 
            style={{ maxWidth: "150px" }} 
          />
        </HashLink>
        <input
          onChange={toggleTheme}
          className="container_toggle"
          type="checkbox"
          id="switch"
          name="mode"
        />
        <label htmlFor="switch">Toggle</label>
        
        <div
          aria-expanded={isActive ? 'true' : 'false'}
          aria-haspopup="true"
          aria-label={isActive ? 'Fechar menu' : 'Abrir menu'}
          className={isActive ? 'menu active' : 'menu'}
          onClick={() => {
            setActive(!isActive)
          }}
        ></div>
      </Router>
    </Container>
  )
}
